/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.block{
  width:660px;
  overflow: hidden;
  &::after{
    display: block;
    clear:both;
  }
}
.block_i{
  width:198px;
  height: 198px;
  background-color: white;
  margin:0 20px 20px 0;
  float: left;
  overflow: hidden;
  border: 1px solid white;
  &:hover{
    border: 1px solid #2B82E8;
  }
  &:nth-child(3n){
    margin-right: 0;
  }
}
.block_img{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border:1px solid #2B82E8;
  border-radius: 50%;
  margin:40px auto 0;
}
.block_count{
  margin-top:14px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
.block_name{
  margin-top: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
