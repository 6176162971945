/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.box{
  padding:32px;
  background-color: white;
  margin:20px auto;
  width:1238px;
}
.back{
  font-size: 20px;
  height:30px;
  line-height: 30px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  display: flex;
  .left{
    background-image: url(/assets/svgs/left.svg);
    background-position: center;
    background-repeat: no-repeat;
    width:20px;
    height:30px;
    cursor: pointer;
  }
}
