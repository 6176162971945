/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.block_c{
  width:1262px;
  margin:32px auto 0;
}
.block_more{
  font-size: 14px;
  font-weight: 400;
  color: #2B82E8;
}
.block_steps{
  margin:16px auto 0;
  display: flex;
  justify-content: space-between;
}
.block_step_i{
  width:280px;
  height:120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #F5F8FA;
  &:hover{
    text-decoration: none;
  }
}
.block_step_triangle{
  position: relative;
  &::before{
    content:"";
    display:block;
    border-top: 60px solid transparent;
    border-right: 32px solid #F5F8FA;
    border-bottom: 60px solid transparent;
    position:absolute;
    top:0;
    left:-32px;
  }
}
.block_line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin:5px 20px;
}
.block_title{
  font-size: 24px;
  font-weight: 500;
  color: #006eff;
}
.block_time{
  font-size: 14px;
  font-weight: 300;
  color: #999999;
}
.block_subtitle{
  font-size: 14px;
  font-weight: 300;
  color: #333333;
  white-space: nowrap;
}
.block_path{
  font-size: 14px;
  font-weight: 300;
  color: #333333;
  margin-left:20px;
}
