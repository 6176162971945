/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.modal{
  :global(.tea-dialog__headertitle){
    font-size: 20px;
  }
}
